import { Percent } from '@cryptoalgebra/kim-sdk';
import { Address } from '@thirdweb-dev/sdk';
import { useMemo } from 'react';
import { create } from 'zustand';

interface Transaction {
  success: boolean;
  loading: boolean;
  error: Error | null;
}

interface PendingTransactions {
  [hash: Address]: Transaction;
}

interface UserState {
  txDeadline: number;
  slippage: Percent | 'auto';
  pendingTransactions: PendingTransactions;
  actions: {
    setTxDeadline: (txDeadline: number) => void;
    setSlippage: (slippage: Percent | 'auto') => void;
    addPendingTransaction: (hash: Address) => void;
    updatePendingTransaction: (hash: Address, transaction: Transaction) => void;
    deletePendingTransaction: (hash: Address) => void;
  };
}

export const useUserState = create<UserState>((set, get) => ({
  txDeadline: 180,
  slippage: 'auto',
  pendingTransactions: {},
  actions: {
    setTxDeadline: txDeadline =>
      set({
        txDeadline
      }),
    setSlippage: slippage =>
      set({
        slippage
      }),
    addPendingTransaction: hash =>
      set({
        pendingTransactions: {
          ...get().pendingTransactions,
          [hash]: {
            loading: true,
            success: false,
            error: null
          }
        }
      }),
    updatePendingTransaction: (hash, transaction) =>
      set({
        pendingTransactions: {
          ...get().pendingTransactions,
          [hash]: transaction
        }
      }),
    deletePendingTransaction: hash => {
      const { pendingTransactions } = get();

      delete pendingTransactions[hash];
      set({
        pendingTransactions
      });
    }
  }
}));

export function useUserSlippageToleranceWithDefault(
  defaultSlippageTolerance: Percent
): Percent {
  const { slippage } = useUserState();

  return useMemo(
    () => (slippage === 'auto' ? defaultSlippageTolerance : slippage),
    [slippage, defaultSlippageTolerance]
  );
}
