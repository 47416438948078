import { Currency, CurrencyAmount } from '@cryptoalgebra/kim-sdk';
import { Address } from '@thirdweb-dev/sdk';
import { useAddress, useContract } from '@thirdweb-dev/react';
import erc20 from '../../../abis/erc20.json';
import { useAsyncFn } from 'react-use';
import { useEffect } from 'react';

export function useNeedAllowance(
  currency: Currency | null | undefined,
  amount: CurrencyAmount<Currency> | undefined,
  spender: Address | undefined
) {
  const account = useAddress();

  const { contract } = useContract(currency?.wrapped.address, erc20);

  const [{ value: allowance }, getAllowance] = useAsyncFn(async () => {
    if (!contract) {
      return;
    }

    return await contract.call('allowance', [account, spender]);
  }, [contract, account, spender, amount?.toSignificant()]);

  useEffect(() => {
    getAllowance();
  }, [getAllowance]);

  return {
    allowance: Boolean(
      !currency?.isNative &&
        // typeof allowance === 'bigint' &&
        allowance !== undefined &&
        amount &&
        amount.greaterThan(allowance.toString())
    ),
    refetch: getAllowance
  };
}
