import { Currency, ExtendedNative, ADDRESS_ZERO } from '@cryptoalgebra/kim-sdk';
import { useAlgebraToken } from './useAlgebraToken';
import {
  DEFAULT_NATIVE_NAME,
  DEFAULT_NATIVE_SYMBOL
} from '@app/constants/common';
import { Address, NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';
import { useChainId } from '@thirdweb-dev/react';
import {
  supportedChains,
  supportedChainsIds
} from '@app/context/AppContextWrapper';

export function useCurrency(
  address: Address | undefined
): Currency | ExtendedNative | undefined {
  const isETH = address === ADDRESS_ZERO || address === NATIVE_TOKEN_ADDRESS;
  const chainId = useChainId();

  const token = useAlgebraToken(isETH ? ADDRESS_ZERO : address);

  if (!chainId || !supportedChainsIds.includes(chainId)) {
    return;
  }

  const extendedEther = ExtendedNative.onChain(
    chainId,
    DEFAULT_NATIVE_SYMBOL,
    DEFAULT_NATIVE_NAME
  );

  return isETH ? extendedEther : token;
}
