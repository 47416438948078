import {
  Currency,
  CurrencyAmount,
  Percent,
  Trade,
  TradeType
} from '@cryptoalgebra/kim-sdk';
import { useNeedAllowance } from './useNeedAllowance';
import { ApprovalState, ApprovalStateType } from 'src/types/approve-state';
import { useMemo } from 'react';
import { Address } from '@thirdweb-dev/sdk';
import { useAsyncFn } from 'react-use';
import { useContract } from '@thirdweb-dev/react-core';

import erc20ABI from '../../../abis/erc20.json';
import { useConfig } from '@app/config';

export function useApprove(
  amountToApprove: CurrencyAmount<Currency> | undefined,
  spender: Address | undefined,
  useWrapped?: boolean
) {
  const token = useWrapped
    ? amountToApprove?.currency?.wrapped
    : amountToApprove?.currency?.isToken
    ? amountToApprove.currency
    : undefined;
  const { allowance: needAllowance, refetch } = useNeedAllowance(
    token,
    amountToApprove,
    spender
  );

  const approvalState: ApprovalStateType = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN;

    const _currency = useWrapped
      ? amountToApprove.currency.wrapped
      : amountToApprove.currency;

    if (_currency.isNative) {
      return ApprovalState.APPROVED;
    }

    return needAllowance ? ApprovalState.NOT_APPROVED : ApprovalState.APPROVED;
  }, [useWrapped, amountToApprove, needAllowance, spender]);

  const { contract } = useContract(
    amountToApprove
      ? (amountToApprove.currency.wrapped.address as Address)
      : undefined,
    erc20ABI
  );

  const [{ value: approvalData, loading: isLoading }, approve] =
    useAsyncFn(async () => {
      if (!contract) {
        return;
      }

      const res = await contract.call('approve', [
        spender,
        amountToApprove ? BigInt(amountToApprove.quotient.toString()) : 0
      ]);

      await refetch();

      return res;
    }, [contract, spender, amountToApprove, refetch]);

  return {
    approvalState: isLoading
      ? ApprovalState.PENDING
      : approvalData && approvalState === ApprovalState.APPROVED
      ? ApprovalState.APPROVED
      : approvalState,
    approvalCallback: approve
  };
}

export function useApproveCallbackFromTrade(
  trade: Trade<Currency, Currency, TradeType> | undefined,
  allowedSlippage: Percent
) {
  const amountToApprove = useMemo(
    () =>
      trade && trade.inputAmount.currency.isToken
        ? trade.maximumAmountIn(allowedSlippage)
        : undefined,
    [trade, allowedSlippage]
  );

  const config = useConfig();

  return useApprove(
    amountToApprove,
    config?.ALGEBRA_ADDRESSES.ALGEBRA_ROUTER ?? ''
  );
}
