import { useContract } from '@thirdweb-dev/react-core';

import { algebraPoolABI } from '../../../abis/v3';
import { useAsync } from 'react-use';

export function useAlgebraPoolTickSpacing({
  address
}: {
  address: string | undefined;
}) {
  const { contract } = useContract(address, algebraPoolABI);

  const { value, loading, error } = useAsync(async () => {
    if (!contract) {
      return;
    }

    return contract.call('tickSpacing');
  }, [contract]);

  return {
    data: value,
    isLoading: loading,
    isError: error
  };
}
